import React from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import Layout from "../../components/Layout/Layout";
import { Heading1 } from "../../components/Typography/Typography";
import styles from "./theoryPage.module.scss";

const TheoryPage = ({ location, pageContext }) => {
  const { title, content } = pageContext;
  return (
    <Layout location={location}>
      <div className={styles.theoryPage}>
        <Heading1>{title}</Heading1>
        {content && <BlockContent blocks={content} />}
      </div>
    </Layout>
  );
};

export default TheoryPage;
